import { yupResolver } from "@hookform/resolvers/yup";
import { generate } from "@pdfme/generator";
import { image, text } from "@pdfme/schemas";
import moment from "moment";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import DriverRequiredFormField from "../../../components/Form/DriverRequiredFormField";
import ConfirmationModal from "../../../components/Modal/ConfirmationModal";
import config from "../../../constants/config";
import logo from "../../../images/swift-transport-logo.png";
import { doLogout, getUserData } from "../../../rtk-slice/globalSlice";
import { postFile } from "../../../utils/api";
import { errorToast, successToast } from "../../../utils/toastFunc";
import template from "../pdf/template.json";
import "../policy-styles.scss";

const validationSchema = Yup.object().shape({
  document: Yup.mixed().required("Signature is required"),
});

function UseOfMobilePhonePolicySignupPdf() {
  const dispatch = useDispatch();
  const userData = useSelector(getUserData);
  const driverId = userData.userContext.userId;
  const { firstName, lastName } = userData.userDetails;
  const [isLoading, setIsLoading] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
  });

  const handleConfirm = () => {
    if (confirmAction) {
      confirmAction();
    }
    setShowConfirmModal(false);
  };

  const handleConfirmButtonClick = (action) => {
    setConfirmAction(() => action);
    setShowConfirmModal(true);
  };

  const onSubmit = async (data) => {
    handleConfirmButtonClick(async () => {
      setIsLoading(true);
      const url =
        config.baseUrl + config.userDocuments.uploadFile + "/" + driverId;
      const formData = new FormData();
      const plugins = { text, image };
      const inputs = [
        {
          fullName: `${firstName} ${lastName ?? ""}`,
          date: moment().format("DD-MM-YYYY"),
          signature: data.document,
        },
      ];

      const pdf = await generate({ template, inputs, plugins });
      const blob = new Blob([pdf.buffer], { type: "application/pdf" });
      const file = new File([blob], "mobile-phone-policy-sign-off.pdf", {
        type: "application/pdf",
      });
      formData.append("document", file);

      const response = await postFile(url, formData);
      window.open(URL.createObjectURL(blob));

      if (response.data === "document uploaded successfully") {
        successToast({ mes: "Document Submitted" });
        dispatch(doLogout());
      } else {
        setIsLoading(false);
        errorToast({ mes: "Error Upload" });
      }
    });
  };
  return (
    <>
      <div className="policy-container">
        <div className="policy-document">
          <div className="d-flex justify-content-center mb-5">
            <img width={220} height={120} src={logo} alt="swift-logo" />
          </div>
          <div className="policy-subheader">
            <div className="policy-title">
              <h1></h1>
              <h2>Use of Mobile Phone Policy</h2>
              <h3>SWI.POL.012</h3>
            </div>
          </div>

          <div className="policy-content">
            <p>
              Drivers or riders can use their mobile phone to make or answer a
              call and use the audio playing function (e.g. music) only if the
              phone is either:
            </p>
            <ol>
              <li>
                In a cradle fixed to the vehicle and doesn't obscure their view
                of the road.
              </li>
              <li>
                Can be operated without touching any part of the phone, such as
                via Bluetooth or voice activation.
              </li>
              <li>
                Drivers can use their mobile phone as a driver's aid (e.g. GPS)
                only if the phone is in a cradle fixed to the vehicle and
                doesn't obscure their view of the road.
              </li>
            </ol>

            <p>
              While driving or riding you cannot use your mobile phone for
              anything else, including:
            </p>
            <ol>
              <li>Texting or audio texting</li>
              <li>Emailing</li>
              <li>Using social media</li>
              <li>Taking photos</li>
              <li>Video messaging</li>
              <li>
                Holding your phone in any way (in hand, on lap, between shoulder
                and ear). Drivers are only allowed to hold a phone to pass it to
                a passenger.
              </li>
            </ol>

            <p>
              If you want to use your phone for any of these functions, your
              vehicle must be parked out of the line of traffic.
            </p>
            <p>
              These functions are not permitted when your vehicle is stopped,
              including when waiting at traffic lights or stuck in traffic.
            </p>
            <p>
              Also, you may not use your mobile phone whilst sitting in your
              truck at a customer's premises or on or walking around a
              customer's premises.
            </p>
            <p>
              Failure to adhere to this policy will result in a written warning.
              Future breaches of this policy will result in further disciplinary
              action up to and including termination of your employment.
            </p>
          </div>

          <div className="policy-footer">
            <p>Approved by Geoff De Santis – Personnel & Compliance Manager</p>
          </div>
          <div className="policy-footer">
            <p>Use of Mobile Phone Policy</p>
            <p>Date Review June 2024</p>
            <p>SWO.POL.012</p>
          </div>
        </div>
      </div>
      <Container>
        <div className="eachPage mb-0">
          <p>
            <strong>Sign off</strong>
          </p>
          <p>
            I have read, understood, and agreed to comply with the content of
            this Inbound container.
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DriverRequiredFormField
              errors={errors}
              firstName={firstName}
              lastName={lastName}
              isLoading={isLoading}
              setValue={setValue}
            />
          </form>

          <ConfirmationModal
            show={showConfirmModal}
            onHide={() => setShowConfirmModal(false)}
            onConfirm={handleConfirm}
            message="Are you sure you want to submit this file?"
          />
        </div>
      </Container>
    </>
  );
}

export default UseOfMobilePhonePolicySignupPdf;
