import { generate } from "@pdfme/generator";
import { image, multiVariableText, text } from "@pdfme/schemas";
import { useEffect, useState } from "react";
import { useGetSfCustomerSignature } from "../../../components/CreditApplication/srfCustomerAPI";
import { eachModalLogoUrl } from "../../../components/CreditApplication/Steppers/StepsFields";
import template from "./template.json";
import { successToast } from "../../../utils/toastFunc";

const modalEnum = {
  CONTAINERS: "Containers",
  COURIERS: "Couriers",
  INTERMODAL: "Intermodal",
};

const paymentTermsValue = {
  SDN: "7 day net",
  FDN: "14 day net",
  TDN: "21 day net",
  TNDN: "30 day net",
};

function removeNewlines(base64String) {
  return base64String.replace(/(\r\n|\n|\r)/gm, ""); // Remove all newlines
}

function detectMimeType(base64String) {
  const signatures = {
    "/": "jpeg",
    i: "png",
    R: "gif",
    U: "webp",
  };
  return signatures[base64String.charAt(0)] || "jpeg";
}

const PdfGenerator = ({ pdfData, setPdfData, setPdfDownloading }) => {
  const { data: signatureImg } = useGetSfCustomerSignature(pdfData.customerId);
  const [imageSrc, setImageSrc] = useState(null);
  const [logoSrc, setLogoSrc] = useState(null);

  useEffect(() => {
    if (signatureImg) {
      const base64String = removeNewlines(signatureImg);
      const mimeType = detectMimeType(base64String);
      setImageSrc(`data:image/${mimeType};base64,${base64String}`);
    }
  }, [signatureImg]);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(
          eachModalLogoUrl[pdfData.creditApplicationModalType]
        );
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
          setLogoSrc(reader.result);
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error("Error loading image:", error);
      }
    };

    fetchImage();
  }, [pdfData]);

  useEffect(() => {
    if (imageSrc && logoSrc) {
      GenerateDownloadPdf(pdfData, imageSrc, logoSrc);
      setPdfData(null);
      setPdfDownloading(false);
    }
  }, [pdfData, imageSrc, logoSrc, setPdfData, setPdfDownloading]);

  return null; // No UI needed
};

export const GenerateDownloadPdf = async (data, imageSrc, logoSrc) => {
  const plugins = { text, image, multiVariableText };
  const inputs = [
    {
      logo: logoSrc,
      header: `Swift ${modalEnum[data.creditApplicationModalType]} Services`,
      field2: "Credit Application",
      companyName: data.companyName,
      abn: data.personalInfo?.abn,
      tradingName: data.personalInfo?.tradingName,
      businessAddress: data.businessInfo?.businessAddress?.address,
      postalAddress: data.personalInfo?.postalAddress?.address,
      BusinessSuburb: data.businessInfo?.businessAddress?.suburb,
      BusinessState: data.businessInfo?.businessAddress?.state,
      BusinessPostcode: data.businessInfo?.businessAddress?.postCode,
      PostalSuburb: data.personalInfo?.postalAddress?.suburb,
      PostalState: data.personalInfo?.postalAddress?.state,
      PostalPostcode: data.personalInfo?.postalAddress?.postCode,
      phoneNo: data.phoneNo,
      emailAddress: data.emailAddress,
      alternatePhoneNo: data.alternatePhoneNo,
      fax: data.personalInfo?.fax,
      emailAddressesForInvoices: data.emailAddressesForInvoices,
      emailAddressesForAccountStatement: data.emailAddressesForAccountStatement,
      contactPerson: data.accountsTeam?.contactPerson,
      authorizingOfficer: data.accountsTeam?.authorizingOfficer,
      tradingEmailAddress: data.accountsTeam?.emailAddress,
      phoneNumber: data.accountsTeam?.phoneNumber,
      authorizingOfficerContactNo:
        data.accountsTeam?.authorizingOfficerContactNo,
      position: data.accountsTeam?.position,
      referenceName1: data.tradeDetails?.[0]?.referenceName,
      contactNumber1: data.tradeDetails?.[0]?.contactNumber,
      emailAddress1: data.tradeDetails?.[0]?.emailAddress,
      additionalContactNumber1: data.tradeDetails?.[0]?.additionalContactNumber,
      referenceName2: data.tradeDetails?.[1]?.referenceName,
      contactNumber2: data.tradeDetails?.[1]?.contactNumber,
      emailAddress2: data.tradeDetails?.[1]?.emailAddress,
      additionalContactNumber2: data.tradeDetails?.[1]?.additionalContactNumber,
      referenceName3: data.tradeDetails?.[2]?.referenceName,
      contactNumber3: data.tradeDetails?.[2]?.contactNumber,
      emailAddress3: data.tradeDetails?.[2]?.emailAddress,
      additionalContactNumber3: data.tradeDetails?.[2]?.additionalContactNumber,
      creditLimit: data.personalInfo?.creditLimit.toString(),
      paymentMethod: data.paymentMethod[0],
      requestedAmount: data.requestedAmount.toString(),
      paymentTerms: paymentTermsValue[data.paymentTerms],
      bank: data.bankDetails?.bank,
      directorName: data.companyDirectors?.[0]?.directorName,
      directorAddress: data.companyDirectors?.[0]?.directorAddress,
      accountHolderName: data.bankDetails?.accountHolderName,
      bsb: data.bankDetails?.bsb,
      accountNumber: data.bankDetails?.accountNumber,
      signaturePersonName: data.signaturePersonName,
      signaturePersonPosition: data.signaturePersonPosition,
      signatureDate: data.signatureDate,
      signaturePic: imageSrc,
    },
  ];

  const pdf = await generate({ template, inputs, plugins });
  const blob = new Blob([pdf.buffer], { type: "application/pdf" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${data.companyName}-${data.creditApplicationModalType}.pdf`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(link.href);
  successToast({ mes: "PDF Downloaded" });
};

export default PdfGenerator;
