import React from "react";
import "../SwiftTrafficSignup/swiftTrafficSignup.scss";
import { useSelector } from "react-redux";
import { getUserData } from "../../rtk-slice/globalSlice";
import LoginForDriver from "../SwiftTrafficSignup/components/LoginForDriver";
import UseOfMobilePhonePolicySignupPdf from "./components/useOfMobilePhonePolicySignupPdf";

function UseOfMobilePhonePolicySignup() {
  const userData = useSelector(getUserData);

  return (
    <div className="swift-traffic">
      {userData && userData.success ? (
        <UseOfMobilePhonePolicySignupPdf />
      ) : (
        <LoginForDriver />
      )}
    </div>
  );
}

export default UseOfMobilePhonePolicySignup;
